<template>
  <div>
    <v-jsf :value="value" @input="update" :schema="jsonSchema" :options="options">
      <template slot="custom-preset" slot-scope="{schema}">
        <schema-preset-component @input="update(updateModel(schema.key, $event))" :itemType="itemType" :fullSchema="jsonSchema" :item-schema="schema" :fullModel="value"/>
      </template>
    </v-jsf>
    <v-text-field v-if="showQuantity" @input="$emit('updateQuantity', parseInt($event))" :rules="quantityRules" value="1" type="number" label="Quantity" min="1" max="10" style="max-width: 150px"/>
  </div>
</template>

<script lang="js">
import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import '@koumoul/vjsf/lib/VJsf.css'
import '@koumoul/vjsf/lib/deps/third-party.js'
import SchemaPresetComponent from './SchemaPresetComponent.vue'

export default  {
    name: 'json-schema-control',
    data() {
      return {
        options: {
          "editMode": "inline"
        },
        quantityRules: [ 
            v => !!v || "This field is required", 
            v => ( v && v >= 1 ) || "Quantity must be at least 1.",
            v => ( v && v <= 10 ) || "Max quantity is 10.",
        ],
      }
    },
    components: {VJsf, SchemaPresetComponent},
    props: {
      value : {type: Object, required: false},
      jsonSchema: {type: Object, required: true},
      showQuantity : {type: Boolean, default: false},
      itemType : {type: Object, required: false},
    },
    computed: {
      hasPresets : function() {
        return this.jsonSchema['x-presets'] && typeof this.jsonSchema['x-presets'] === 'object'
      },
    },
    methods: {
      update: function(evt) {
        this.$emit('input', evt)
      },
      updateModel: function(presetKey, presetModel) {

        // remove any props not in the preset model but currently
        // in the overall model from a previous setting
        var presetProps = this.jsonSchema['x-presets'][presetKey]
        presetProps.forEach(prop => {
          if (this.value[prop] && !presetModel[prop]) {
            this.$delete(this.value, prop)
          }
        });
        
        // for each key in the preset model, remove
        // any from the current value that isn't in the preset model
        var newModel = {...this.value, ...presetModel}
        return newModel
      }
    },
    mounted () {},
    
}
</script>

<style scoped>
</style>