
import { Component, ProvideReactive, Watch } from "vue-property-decorator"
import { BaseMaintenanceSystemComponent } from "../base/BaseMixins"
import InstantUpdateBoolean from '@/components/forms/InstantUpdateBoolean.vue'
import InstantUpdateColorPicker from '@/components/forms/InstantUpdateColorPicker.vue'
import InstantUpdateTextField from '@/components/forms/InstantUpdateTextField.vue'
import BasePanel from '@/components/base/BasePanel.vue'
import MaintenanceItemPackageList from "./MaintenanceItemPackageList.vue"
import JobTemplateList from "../jobs/JobTemplateList.vue"
import ServiceLoopTemplateList from "../serviceLoops/ServiceLoopTemplateList.vue"

@Component({components:{ServiceLoopTemplateList, JobTemplateList, MaintenanceItemPackageList, BasePanel, InstantUpdateBoolean, InstantUpdateColorPicker, InstantUpdateTextField}})
export default class MaintenanceSystemDetail extends BaseMaintenanceSystemComponent {

  @ProvideReactive() maintenanceSystem = this.resource

  tab : string = "tab-items"

  @Watch("resource", {immediate: true})
  resourceUpdated(newVal : any) {
    this.maintenanceSystem = newVal
  }

}
