
import { Component, Mixins } from "vue-property-decorator"
import JobTemplateFormDialog from "@/components/jobs/JobTemplateFormDialog.vue"
import BaseYesNoDialog from "@/components/base/BaseYesNoDialog.vue"
import { AbstractMaintenanceSystemList } from "../forms/FormsMixin"
import {FormsMetadataResource} from "@/resources"
import { BaseJobTemplateComponent } from "../base/BaseMixins"

@Component({components : {JobTemplateFormDialog, BaseYesNoDialog}})
export default class JobTemplateList extends Mixins(AbstractMaintenanceSystemList, BaseJobTemplateComponent) {
  
  headers: any = [
      { text: 'Name', value: 'name' },
      { text: 'Workflow', align: 'start', sortable: true, value: 'workflowKey'},
      { text: 'Duration (est. in hours)', align: 'start', sortable: false, value: 'duration'},
      { text: 'Actions', value: 'actions', sortable: false },
  ]

  get sortField() { return "name" }
  get sortDirection() : "asc" | "desc" { return "asc" }
  get sortCaseInsensitive() { return true }

  get workflowEnums() {
    return FormsMetadataResource.Instance.getHalOptions("jobtemplate", "workflowKey")
  }

  workflowName(key : any) {
    const enumValue = this.workflowEnums.find((wf:any) => wf.value === key)
    return enumValue ? enumValue.text : "UNKNOWN"
  }  

 }
