
import { EntityResource } from "@/resources"
import { orderBy } from "lodash-es"
import { Component, Prop} from "vue-property-decorator"
import { BaseEntityResourceComponent } from "./BaseMixins"

@Component({components : {}})
export default class BaseResourceSelector extends BaseEntityResourceComponent {

  @Prop({default: 'name'}) readonly labelProperty !: string
  @Prop() readonly labelProviderFunction !: Function
  @Prop() readonly urlWatcherProviderFunction !: Function
  @Prop() readonly noSelection !: {text: string, value: string, resource: null}
  @Prop({default: true}) readonly noSelectionDivider !: boolean
  @Prop() readonly selectListClass !: string
  @Prop() readonly idProp !: string
  @Prop() readonly sortFunction !: Function

 
  // @ts-ignore
  @Prop({default: () => true}) readonly filterFunction !: () => true
 
  get selections() {
    let itemSelections : any = this.resources.filter(this.filterFunction).map((r:any) => ({
      value: this.idProp ? r.data()[this.idProp] : EntityResource.fullUri(r.resource.uri), // we want the full url, no projections. 
      text: this.labelProviderFunction ? this.labelProviderFunction(r) : r.data()[this.labelProperty],
      resource: r
    }))

    itemSelections = orderBy(itemSelections, [(value : any) => value.text], "asc")
    

    // add no selection if present
    if (this.noSelection) {
      if (this.noSelectionDivider) itemSelections.unshift({divider: true})
      itemSelections.unshift(this.noSelection)
    }

    // build watch URLS *paths* using the non filtered array (we may want to updated based on a URL we are
    // filtering out)
    if (this.urlWatcherProviderFunction) {
      var allUrls = this.resources.map((r:any) => new URL(EntityResource.fullUri(r.resource.uri)).pathname)
      this.addAdditionalWatchedURIs(this.urlWatcherProviderFunction(allUrls))
    }

    return itemSelections
  }
}
