
import { Component, Prop, Vue, Watch} from "vue-property-decorator"
import DaySelector from '@/components/serviceLoops/DaySelector.vue'
import MonthSelector from '@/components/serviceLoops/MonthSelector.vue'
import { RRule } from 'rrule'
import {Info} from 'luxon'
import {capitalize} from "@/util"

@Component({components : {DaySelector, MonthSelector}})
export default class RecurrenceControl extends Vue {

  @Prop() readonly value !: string
  @Prop({default: "Recurrence"}) readonly label !: string
  @Prop({default: false}) readonly menu !: boolean
  @Prop({default: false}) readonly allowOneTime !: boolean

  ruleText : string = this.value
  parseError : any
  customDialog : boolean = false
  showMonths : boolean = false
  descriptionCache : any[] = []
  rruleParts : any = {
      interval : 1,
      day : undefined,
      frequency : "WEEKLY",
      months : []
  }
  frequencyItems : any[] = [
      {value: "DAILY", text:"day"}, 
      {value: "WEEKLY", text:"week"}, 
      {value: "MONTHLY", text:"month"}, 
      {value: "YEARLY", text:"year"}, 
  ]

  defaultPresets : any[] = [
      {value:"RRULE:FREQ=MONTHLY;INTERVAL=6"},
      {value:"RRULE:FREQ=YEARLY;INTERVAL=1"},
      {value:"RRULE:FREQ=MONTHLY;INTERVAL=1"},
      {value:"RRULE:FREQ=MONTHLY;INTERVAL=3"},
      {value:"RRULE:FREQ=MONTHLY;INTERVAL=4"},
  ]
  customPreset : object | null = null

  get presets() {
    const thePresets = [...this.defaultPresets]

    if (this.allowOneTime) {
      thePresets.unshift({divider: true})
      thePresets.unshift({value: null, text: "One time event (no schedule)"})
    }

    if (this.customPreset) {
      thePresets.push(this.customPreset)
    }
    return thePresets
  }

  get rule() {
    try {
      return this.rruleFromString(this.ruleText)
    } catch (e) {
      return undefined
    }
  }

 get ruleToText() {
    return this.ruleDescription(this.ruleText)
  }

  get ruleToString() {
    try {
      return this.rruleFromString(this.ruleText).toString()
    } catch (e) {
      return ""
    }
  }

  rruleFromString(val : string) {
    var roptions = RRule.parseString(val)
    // https://github.com/jakubroztocil/rrule/issues/389
    if (val.indexOf("FREQ=WEEKLY") > 0 && val.indexOf("BYDAY") == -1) {
      roptions.byweekday = []
    }
    return new RRule(roptions, false)
  }

  saveCustom() {
    this.customDialog = false
    this.customPreset = {value:this.ruleText}
  }
   
  parseRuleText(val : string) {
    try {
      var rrule = this.rruleFromString(val)

      this.rruleParts.frequency = rrule.options.freq !== undefined ? RRule.FREQUENCIES[rrule.options.freq] : RRule.FREQUENCIES[RRule.MONTHLY]
      this.rruleParts.interval = rrule.options.interval
    
      // if BYMONTH not set, RRule will use current month, we don't want this
      if (val.indexOf("BYMONTH") != -1) {
        this.rruleParts.months = rrule.options.bymonth && rrule.options.bymonth.length > 0 ? rrule.options.bymonth : undefined
      } else {
        this.rruleParts.months = undefined
      }

      // TODO defaults to 1st weekday of month if yearly interval (see buildRuleText())
      // if bynweekday (note the 'n'), this is the xth weekday of the months, 
      // else ?
      if (Array.isArray(rrule.options.bynweekday) && rrule.options.bynweekday.length > 0) {
        // [day of week (mon=0), xth] -> [3,1] = 1st thursday of the month
        this.rruleParts.day = Info.weekdays()[rrule.options.bynweekday[0][0]].toUpperCase()
      } else if (Array.isArray(rrule.options.byweekday) && rrule.options.byweekday.length > 0 ) {
        this.rruleParts.day = Info.weekdays()[rrule.options.byweekday[0]].toUpperCase() 
      } else {
        this.rruleParts.day = null
      }
      
    } catch (e) {
      console.error("Parsing RRULE error: " + e)
      // eslint
    } 
  }
      
  buildRuleText() {
    this.ruleText = `RRULE:FREQ=${this.rruleParts.frequency};INTERVAL=${this.rruleParts.interval}`
    if (this.rruleParts.day) {
      if (this.rruleParts.frequency == "MONTHLY"
      ||  this.rruleParts.frequency == "YEARLY") {
        // we choose the first day in that period
        // TODO support first/last etc.
        this.ruleText += `;BYDAY=+1${this.rruleParts.day.substring(0,2)}` 
      } else {
        this.ruleText += `;BYDAY=${this.rruleParts.day.substring(0,2)}`
      }
    }
    if (this.rruleParts.months && this.rruleParts.months.length > 0) {
      this.ruleText += `;BYMONTH=${this.rruleParts.months.join(',')}`
    }
  }

  ruleDescription(rule : any) {
    try {
      // if there is a text attribute, use it
      if (rule.text) {
        return rule.text
      }

      // check cache
      var ruleValue = typeof rule === "object" ? rule.value : rule
      if (this.descriptionCache[ruleValue]) {
        return this.descriptionCache[ruleValue]
      }
      var txt = capitalize(this.rruleFromString(ruleValue).toText())
      this.descriptionCache[ruleValue] = txt
      return txt
    } catch (e) {
      return ""
    }
  }

  @Watch("rruleParts", {deep : true})
  rrulePartsWatcher() {
    // clear day if daily
    if (this.rruleParts.frequency === 'DAILY') {
      this.rruleParts.day = undefined
    }
    this.buildRuleText()
  }
   
  @Watch("showMonths")
  showMonthsWatcher(newVal : any) {
    // if doing months, switch rule to 1/year
    if (newVal) {
      this.rruleParts.frequency = "YEARLY"
      this.rruleParts.interval = 1
    } else {
      this.rruleParts.months = []
    }
  }

  @Watch("ruleText")
  ruleTextWatcher(val : any, old: any) { 
      if (val !== old) {
        if (val) this.parseRuleText(val)
        this.$emit('input', val)
      }
  }

  @Watch("value", {immediate: true})
  valueWatcher(val : any) {
    this.ruleText = val
    if (val && val.trim()) {
      this.parseRuleText(val)

      // check if the rule is in our default presets, if not
      // add it as a custom so it can be selected & shows up
      // in the list.
      this.customPreset = (val && !this.defaultPresets.find(dp => dp.value == val)) ? {value:val} : null
    }
  }
}
