
import { Component, Prop, Vue} from "vue-property-decorator"
import {Info} from 'luxon'

@Component({components : {}})
export default class DaySelector extends Vue {

  @Prop() readonly value !: string
  @Prop() readonly label !: string

  dayIdx : number | null = this.init()
  selectedDay : string = ""
  dayOrder : number[] = [6,0,1,2,3,4,5]

  get days() : string[] {
    return Info.weekdays().map((d : any) => d.substring(0,1).toUpperCase()) 
  }

  get daysFull() : string[] {
    return Info.weekdays().map((d : any) => d.toUpperCase())
  }
    
  changed(dayIdx : number) {
    this.selectedDay = this.daysFull[this.dayOrder[dayIdx]]
    this.$emit("input", this.selectedDay)
  }

  init() : any {
    return this.value ? (Info.weekdays().map((d : any) => d.toUpperCase()).indexOf(this.value) + 1) % 7: null
  }
}
