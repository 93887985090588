
import { Component, Mixins, Prop } from "vue-property-decorator";
import {JobTemplateResource, AssociationResource, FormsMetadataResource, ServiceLoopTemplateResource} from "@/resources"
import ServiceLoopTemplateFormDialog from "@/components/serviceLoops/ServiceLoopTemplateFormDialog.vue"
import BaseYesNoDialog from "@/components/base/BaseYesNoDialog.vue"
import { AbstractMaintenanceSystemList } from "../forms/FormsMixin";
import { BaseServiceLoopTemplateComponent } from "../base/BaseMixins";

@Component({components : {ServiceLoopTemplateFormDialog, BaseYesNoDialog}})
export default class ServiceLoopTemplateList extends Mixins(AbstractMaintenanceSystemList, BaseServiceLoopTemplateComponent) {

  @Prop({ required : true }) readonly jobTemplatesAssociationResource!: AssociationResource<JobTemplateResource[]>

  headers: any = [
        { text: 'Job', value: 'jobTemplate' },
        { text: 'Job type', align: 'start', sortable: true, value: 'workflowKey'},
        { text: 'Repeats', value: 'recurrence'},
        { text: 'Actions', value: 'actions', sortable: false },
      ]

  // service loops use the name of the job template they are based on
  customSortField = (er : ServiceLoopTemplateResource) => er.data().jobTemplate.name

  get workflowEnums() {
    return FormsMetadataResource.Instance.getHalOptions("jobtemplate", "workflowKey")
  }

  workflowName(key : any) {
    const enumValue = this.workflowEnums.find((wf:any) => wf.value === key)
    return enumValue ? enumValue.text : "UNKNOWN"
  }  

 }
