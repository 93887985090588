
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import AsyncComputed from 'vue-async-computed-decorator'
import { Debounce } from 'vue-debounce-decorator'
import {PropertyResource, ClientResource} from '@/resources'
import {isEqual} from 'lodash-es'

@Component
export default class PropertySearchField extends Vue {
  
  @Prop({default: true}) changeRoute !: boolean
  @Prop({default: true}) soloInverted !: boolean

  selection: any = null
  search : string = ""
  propertyResource = new PropertyResource()
  toggleHelper : boolean = false

  mounted() {
    this.$nextTick(()=>{
      this.$root.$on('focusPropertySearch', () => {
        window.scrollTo(0, 0);
        //@ts-ignore
        this.$refs.propertySearch.focus()
        setTimeout(() => {
          this.toggleHelper = true
        }, 250);
        setTimeout(() => {
          this.toggleHelper = false
        }, 3000);
      });
    });
  }
  
  @AsyncComputed({shouldUpdate() {return false}})
  async properties () {
    if (!this.search) {
      return []
    }
    return (await this.propertyResource.searchByAddressOrName).getAssociation({addressOrName: this.search})
  }

  getBusinessAndOrClientName(businessName : string, clientName : string) {
    if (clientName && clientName.trim() && businessName && businessName.trim() && clientName.trim() != businessName.trim()) {
      return businessName.trim() + " (" + clientName.trim() + ")"
    }
    else if (clientName && clientName.trim()) {
      return clientName.trim()
    }
    else if (businessName && businessName.trim()) {
      return businessName
    }
    return "No client file attached."
  }

  get clientUtil() : any {
    return ClientResource
  }

  get propertyUtil() : any {
    return PropertyResource
  }

  propertyAddress(p : any) : string {
    if (!p.address) {
        return "";
    }
    return p.address.city ? p.address.address + ", " + p.address.city : p.address
  }

  getPropertyAddressFromClient(property : any) {
    // TODO assumes one prop
    return this.propertyAddress(property.data())
  }

  @Watch('selection')
  onSelectionChanged(newVal : any) {
    const newId = newVal ? newVal.data().id : undefined

    if (newId && newId != this.$route.params.pid) {

      // force reload of full property, not projection
      var p = new PropertyResource(newId)
      p.get(false).then(() => {

        if (this.changeRoute) {
          this.$router.push({ name: "property", params: { pid : newId } })
          this.selection = null
        }
        else {
          this.$emit('select', p)
        }
      })
    }
    else if (!newId) {
      this.$emit('select', null)
      this.selection = null
    }
  }

  @Watch('search')
  @Debounce(300)
  onSearchChanged(newVal : any, oldVal : any) {
    if (newVal && !isEqual(newVal, oldVal)) {
      this.$asyncComputed.properties.update()
    }
    else {
      this.search = ""
      this.selection = null

    }
  }
    
}
