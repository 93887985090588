
import {Component, Prop, Watch} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import {EntityResource} from "@/resources"
import { BaseEntityResourceComponent, RequiredProjections } from './BaseMixins'
import { cloneDeep, set, tap } from 'lodash-es'

@RequiredProjections("*")
@Component({components: {BaseDialog}})
export default class BaseResourceFormDialog extends BaseEntityResourceComponent { 

  @Prop({ required : true }) readonly value!: boolean
  @Prop({ required : true }) readonly entityCollectionResource!: EntityResource
  @Prop({ required : true }) readonly newModelFunction!: Function
  @Prop() readonly title!: string
  @Prop() readonly titlePrefix!: string
  @Prop({ default : 1 }) readonly quantity!: number

  // eslint-disable-next-line no-unused-vars
  @Prop({ }) readonly postSaveCallback!: (er : any) => Promise<any>
  // eslint-disable-next-line no-unused-vars
  @Prop({ }) readonly preSaveCallback !: (formData:any) => Promise<any>

  saving : boolean = false
  saveError : any | null = null
  valid : boolean = true
  formModel : any = null
  initingModel : boolean = false

  @Watch("value", {immediate:true})
  dialogValueChanged(newVal : any) {
    // if resource is null, this will be the 
    // call that sets the form data, else postEntityUpdate()
    // will get called after the resource load and overwrite
    // this.
        
    // reset form
    if (newVal) {
      this.formModel = this.newModelFunction()
      this.initingModel = true

      // reset form validation
      this.$nextTick(() => {
        const theForm : any = this.$refs.form
        theForm.resetValidation()

      })
    }

  }

  postEntityUpdate() {
    this.formModel = this.newModelFunction()

    // should not be an array, if so, being initialized incorrectly so warn
    if (Array.isArray(this.formModel)) {
      throw new Error("Form model is an array, initialized incorrectly ?")
    }
  }

  @Watch("formModel", {immediate:true})
  formModelChanged(newVal : any) {
    const theForm : any = this.$refs.form

    // only validate the form if not coming from init.
    if (theForm && newVal && !this.initingModel) {
      this.valid = theForm.validate()
    }
    this.initingModel = false
  }

  updateFormModel(newVal : any) {
    this.formModel = newVal
  }

  closeDialog() {
    this.$emit('input', false)
  }

  get dialogTitle() {
    return (this.titlePrefix ? this.titlePrefix : (this.resource ? "Edit" : "New")) + " " + (this.title ? this.title : this.entityCollectionResource.label.toLowerCase())
  }
  get buttonTitle() {
    return this.resource ? "Update" : "Add"
  }


  /**
   * Used to allow altering form data from outside form element.  Sets the new value on our model after cloning, then performing an input event with the cloned and merged object.
   * @param {*} key Key on object to update
   * @param {*} newValue new value to set
   * @param {*} isArray if the key is for an array property
   * @param {*} arrayIdx array index to remove if an array splice, undefined for add
   */
  doUpdate(key: string, newValue: any, isArray?: boolean, arrayIdx?: number) {
    this.doUpdateObject(this.formModel, key, newValue, isArray, arrayIdx)
  }

  /**
   * Used to allow altering form data from outside form element.  Sets the new value on our model after cloning, then performing an input event with the cloned and merged object.
   * @param {*} valueObject The parent object of the property (key) to update
   * @param {*} key Key on value to update
   * @param {*} newValue new property value to set
   * @param {*} isArray if the key is for an array property
   * @param {*} arrayIdx array index to remove if an array splice, undefined for add
   */
   doUpdateObject(valueObject: any, key: string, newValue: any, isArray?: boolean, arrayIdx?: number) {

    let clonedAndMerged
    if (!isArray) {
      clonedAndMerged = tap(cloneDeep(valueObject), v => set(v, key, newValue))
    } else {
      clonedAndMerged = arrayIdx === undefined
        ? tap(cloneDeep(valueObject), v => v[key].push(newValue))
        : tap(cloneDeep(valueObject), v => v[key].splice(arrayIdx, 1))
    }

    this.updateFormModel(clonedAndMerged)
  }

  async doSave() {
    const theForm : any = this.$refs.form
    this.valid = theForm.validate()

    if (this.valid) {
      this.saving = true

      if (this.preSaveCallback) {
        try {
          await this.preSaveCallback(this.formModel)
        }
        catch (e) {
          console.error(e)
          this.saveError = e
          this.saving = false   
          return
        }
      }

      this.save()

    }
  }

  save() {
    // we are either doing an update, or a multiple insert
    let saveEventName = 'savedAdd'
    let actions : Promise<any>[] = []
    if (this.resource) {
      saveEventName = 'savedEdit'
      actions.push(this.resource.mergePatch(this.formModel)) // put doesn't update relationships
    } else {
      // check quantity
      for (var i=0; i < this.quantity; i++) {
        actions.push(this.entityCollectionResource.post(this.formModel))
      }
    }

    // we need to do the update/insert first in order to pass any results
    // to the post save callback
    Promise.all(actions).then((results) => {
      const firstResult = results[0]

      this.$emit(saveEventName, firstResult)

      // call save/add and then any post save calls
      if (this.postSaveCallback) {

        Promise.all([this.postSaveCallback(firstResult)]).then(() => {
          this.closeDialog()
        }).catch((err) => {
          console.error(err)
          this.saveError = err
        }).finally(() => {
          this.saving = false
        })
      } 
      else {
        this.closeDialog()
        this.saving = false
      }

    }).catch((err) => {
      console.error(err)
      this.saveError = err
      this.saving = false
    })
  }

}
