
import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({components: {}})
export default class BasePanel extends Vue {
  @Prop() readonly title!: string
  @Prop({default: 'py-1'}) readonly titleClass!: string
  @Prop() readonly defaultClasses !: string
  @Prop() readonly width !: string | number
  @Prop({default:"auto"}) readonly overflowY !: string
  @Prop({default:"auto"}) readonly overflowX !: string
  @Prop({default: 'fit-content'}) readonly textHeight !: any
}
