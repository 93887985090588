
import { Component, Prop } from "vue-property-decorator"
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import RecurrenceControl from "@/components/serviceLoops/RecurrenceControl.vue"
import BaseResourceSelector from "@/components/base/BaseResourceSelector.vue"
import { AssociationResource, JobTemplateResource } from "@/resources"
import AsyncComputed from 'vue-async-computed-decorator'

@Component({components : {RecurrenceControl, BaseResourceSelector}})
export default class ServiceLoopTemplateForm extends ValueBasedFormsMixin {
  @Prop({required : true}) readonly jobTemplatesAssociationResource !: AssociationResource<JobTemplateResource[]>

  getHalFormName() : string { return "servicelooptemplate" }

  @AsyncComputed()
  async jobTemplateResources() {
    return this.jobTemplatesAssociationResource.getAssociation()
  }

}
