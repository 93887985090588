
import {Vue, Component} from 'vue-property-decorator'
import {ApiResource, EntityResource, MaintenanceSystemsResource} from '@/resources'
import BaseOptionsMenu from '@/components/base/BaseOptionsMenu.vue'
import MaintenanceSystemImportDialog from "@/components/maintenanceSystems/MaintenanceSystemImportDialog.vue"
import {downloadFile} from "@/util"
import MaintenanceSystemDetail from '@/components/maintenanceSystems/MaintenanceSystemDetail.vue'
import MaintenanceSystemsNavigator from '@/components/maintenanceSystems/MaintenanceSystemsNavigator.vue'
import BasePanel from '@/components/base/BasePanel.vue'
import AsyncComputed from 'vue-async-computed-decorator'
import PropertySearchField from '@/components/properties/PropertySearchField.vue';


@Component({components : {BasePanel, MaintenanceSystemDetail, PropertySearchField, MaintenanceSystemsNavigator, MaintenanceSystemImportDialog, BaseOptionsMenu}})
export default class MaintenanceSystemsView extends Vue {
  
  selectedMaintenanceSystem : string | null = null
  importDialog : boolean = false

  get selectedResource() {
    return this.selectedMaintenanceSystem ? new MaintenanceSystemsResource(this.selectedMaintenanceSystem) : null
  }

  @AsyncComputed()
  async hasStuff() {
    var loopTemplates = await ApiResource.Instance.serviceLoopTemplates.getAssociation({size: 1})
    var jobTemplates = await ApiResource.Instance.jobTemplates.getAssociation({size: 1})
    return loopTemplates.length + jobTemplates.length > 0
  }

  exportSystems() {
    downloadFile(new EntityResource("admin/export/loops").resource.uri)
  }

}
