
import { Component } from "vue-property-decorator"
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import JsonEditor from "@/components/maintenanceSystems/JsonEditor.vue"
import JsonSchemaControl from "@/components/maintenanceSystems/JsonSchemaControl.vue"

@Component({components : {JsonEditor, JsonSchemaControl}})
export default class MaintenanceItemPackageForm extends ValueBasedFormsMixin {
  getHalFormName() : string { return "maintenanceitempackage" }

  model : object = {}
}
