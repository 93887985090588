
import { Component, Prop, Vue} from "vue-property-decorator"
import {Info} from 'luxon'

@Component({components : {}})
export default class MonthSelector extends Vue {

  @Prop() readonly value !: any[]
  @Prop() readonly label !: string

  get monthIndexes() {
    return this.value ? this.value.map(i => i-1) : []
  }

  get months() {
    return Info.months('short').map(m => m.toUpperCase()) 
  }
  
  changed(monthIndexes : any) {
    const selectedMonths = monthIndexes && monthIndexes.length > 0 ? monthIndexes.map((i:any) => i+1).sort((a:any, b:any) => a - b) : []
    this.$emit("input", selectedMonths)
  }
}
