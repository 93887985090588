
import { Component } from "vue-property-decorator"
import {InstantUpdateFieldMixin} from "@/components/forms/FormsMixin"

@Component({ components: {} })
export default class InstantUpdateColorPicker extends InstantUpdateFieldMixin {

  swatches : string[][] = [
   ["#3F51B5", "#757CE8", "#002884"], 
   ["#2196F3", "#6EC5FF"],
   ["#EB5757", "#F2994A", "#F2C94C", "#219653", "#27AE60", "#6FCF97"],
   ["#333333", "#4F4F4F", "#BDBDBD"]
  ]

  get halPropsObject() {
    return this.halProps(this.propName, this.$attrs);
  }
}
