
import {Component, InjectReactive, Mixins} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import JobTemplateForm from "@/components/jobs/JobTemplateForm.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, MaintenanceSystemsResource} from "@/resources"
import { BaseJobTemplateComponent } from '../base/BaseMixins'

@Component({components: {BaseResourceFormDialog, JobTemplateForm}})
export default class JobTemplateFormDialog extends Mixins(ResourceFormDialogMixin, BaseJobTemplateComponent) { 

  @InjectReactive() readonly maintenanceSystem!: MaintenanceSystemsResource

  newModelFunction() : any {
    return this.rdata
            ? this.rdata
            : {...SettingsResource.defaultObject("jobTemplate"), 
              maintenanceSystem: this.maintenanceSystem.resource.uri }
  }
}
