
import {Component, InjectReactive, Mixins, Prop} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import ServiceLoopTemplateForm from "@/components/serviceLoops/ServiceLoopTemplateForm.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, AssociationResource, JobTemplateResource, MaintenanceSystemsResource} from "@/resources"
import { BaseServiceLoopTemplateComponent, RequiredProjections } from '../base/BaseMixins'
import AsyncComputed from 'vue-async-computed-decorator'

@RequiredProjections("serviceLoopTemplateInlineJobTemplate")
@Component({components: {BaseResourceFormDialog, ServiceLoopTemplateForm}})
export default class ServiceLoopTemplateFormDialog extends Mixins(ResourceFormDialogMixin, BaseServiceLoopTemplateComponent) { 

  @InjectReactive() readonly maintenanceSystem!: MaintenanceSystemsResource
  @Prop({required : true}) readonly jobTemplatesAssociationResource !: AssociationResource<JobTemplateResource[]>

  @AsyncComputed()
  async fetchJobTemplates() {
    return this.jobTemplatesAssociationResource.getAssociation()
  }

  newModelFunction() : any {
    if (this.rdata) {
      return {...this.rdata, jobTemplate : new JobTemplateResource(this.rdata.jobTemplate.id).resource.uri}
    }
    
    return {...SettingsResource.defaultObject("serviceLoopTemplate"), 
            maintenanceSystem: this.maintenanceSystem.resource.uri }
  }

}
