
import { MaintenanceItemTypeResource, SchemaPresetKeyValueResource } from "@/resources";
import AsyncComputed from "vue-async-computed-decorator";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({components:{}})
export default class SchemaPresetDropdown extends Vue {

  @Prop({required: true}) label !: string
  @Prop({required: true}) presetKey !: string
  @Prop() readonly itemType !: MaintenanceItemTypeResource
  
  errorMessages : string | null = null
  deleting : boolean = false
  adding : boolean = false

  apiResource : SchemaPresetKeyValueResource = new SchemaPresetKeyValueResource()
  
  model : any | null = null
  search : string | null = ""

  addTimeout !: any
  deleteTimeout !: any
  useCache : boolean = true

  @AsyncComputed()
  async items() {
    if (!this.itemType) return []
    var results = await this.apiResource.searchByKeyAndType.getAssociation({presetKey : this.presetKey, id: this.itemType.data().id}, this.useCache)
    this.useCache = true
    
    return results.map(r => this.mapResourceData(r.data()))
  }

  @Watch("model")
  modelChanged(val : any) {
    this.errorMessages = null

    if (val === null || !this.itemType) {
      return
    }
    
    if (typeof val == 'object') {
      this.$emit("input", val.text)
      return
    }

    this.addPresetValue(val.trim())
    this.$emit("input", val.trim())
  }

  mapResourceData(resourceData : any) {
    return {id : resourceData.id, text: resourceData.presetValue}
  }

  addPresetValue(presetValue : any) {
      this.adding = true

      this.apiResource.post({presetKey : this.presetKey, presetValue : presetValue, maintenanceItemType: this.itemType.uriFull})
      .then((result) => {
        // set model to the object representation of this new object
        this.model = this.mapResourceData(result.data)

        // refresh
        this.useCache = false
        this.$asyncComputed.items.update()
      })
      .finally(() => this.adding = false)
  }
  
  deletePresetValue(item : any) {
    this.deleting = item.id
    
    new SchemaPresetKeyValueResource(item.id).delete().then(() => {

      // clear model if deleting it
      if (this.model && this.model.id == item.id) {
        this.model = null
      }

      // refresh
      this.useCache = false
      this.$asyncComputed.items.update()
    })
    .finally(() => this.deleting = false)
  }

  filter (item : any, queryText : any, itemText : any) {
    const hasValue = (val : any) => val != null ? val : ''

    const text = hasValue(itemText)
    const query = hasValue(queryText)

    return text.toString()
      .toLowerCase()
      .indexOf(query.toString().toLowerCase()) > -1
  }
}
