
import { Component } from "vue-property-decorator"
import {InstantUpdateFieldMixin} from "@/components/forms/FormsMixin"

@Component({ components: {} })
export default class InstantUpdateTextField extends InstantUpdateFieldMixin {

  get halPropsObject() {
    return this.halProps(this.propName, this.$attrs);
  }
}
