
import { MaintenanceItemTypeResource, SchemaPresetModelResource } from "@/resources";
import { isEqual } from "lodash-es";
import AsyncComputed from "vue-async-computed-decorator";
import { Component, Prop, Vue } from "vue-property-decorator";
import SchemaPresetDropdown from "./SchemaPresetDropdown.vue";

@Component({components:{SchemaPresetDropdown}})
export default class SchemaPresetComponent extends Vue {

  @Prop() readonly itemType !: MaintenanceItemTypeResource
  @Prop({required:true}) readonly fullSchema !: any
  @Prop({required:true}) readonly itemSchema !: any
  @Prop({required:true}) readonly fullModel !: any

  value : any | null = null
  adding : boolean = false
  deleting : boolean = false
  presetDialog : boolean = false
  presetModel : any = {}
  useCache : boolean = true

  apiResource : SchemaPresetModelResource = new SchemaPresetModelResource()

  valueComparator : Function = (a : any, b : any) => isEqual(a,b)

  get presetRules() {
    if (this.fullSchema.required && this.fullSchema.required.includes(this.itemSchema.key)) {
      return [
        (v:any) => (typeof(v) === 'object' && v !== null && !!Object.keys(v) && !!Object.keys(v).length) || 'Item must be selected.'
      ]
    }
    else return []
  }

  @AsyncComputed()
  async items() {
    if (!this.itemType) return []
    var results = await this.apiResource.searchByTypeAndName.getAssociation({id: this.itemType.data().id, presetName: this.itemSchema.key}, this.useCache)
    this.useCache = true

    var itemsData = results.map(r => r.data())

    // locate data based on full model data, create a temp
    // item model to test with
    var testObj : { [key: string]: any } = {}
    this.fullSchema['x-presets'][this.itemSchema.key].forEach((k:string) => {
      testObj[k] = this.fullModel[k]
    });
    
    this.value = itemsData.find((d:any) => isEqual(d.presetModel,testObj))


    return itemsData
  }


  updatePresetModel(key: string, val : any) {
    this.$set(this.presetModel, key, val)
  }

  created() {
    if (!this.fullSchema['x-presets'][this.itemSchema.key]) {
      throw new Error("Missing x-preset configuration for preset property '" + this.itemSchema.key + "'")
    }
  }

  deletePresetModel(item : any) {
    this.deleting = item.id
    
    new SchemaPresetModelResource(item.id).delete().then(() => {

      // clear model if deleting it
      if (this.value && this.value.id == item.id) {
        this.value = null
      }

      // refresh
      this.useCache = false
    })
    .finally(() => this.deleting = false)
  }

  addPresetModel() {
    this.adding = true

    var validKeys = this.fullSchema['x-presets'][this.itemSchema.key].filter((k:any) => this.presetModel[k])
    var presetValue = validKeys.map((k:any) => this.presetModel[k]).join(" ")

    this.apiResource.post({
      presetModel : this.presetModel,  
      value : presetValue,
      presetName : this.itemSchema.key,
      maintenanceItemType: this.itemType.uriFull})
      .then((result) => {
        // set selection to the new obj
        this.value = result.data

        // set val
        this.$emit('input', this.presetModel)

        // refresh
        this.useCache = false

        this.presetDialog = false
      })
      .finally(() => this.adding = false)
      
  }
 
}
