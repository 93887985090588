
import { Component, Vue, Prop } from "vue-property-decorator"
import AsyncComputed from 'vue-async-computed-decorator'
import {ApiResource} from "@/resources"

@Component({ components: {} })
export default class MaintenanceSystemsNavigator extends Vue {

  @Prop({default: false}) readonly showFolders !: boolean
  @Prop({default: false}) readonly selectable !: boolean
  @Prop({default: false}) readonly leaf !: boolean


  selectedSystems : any[] = []
  
  @AsyncComputed()
  async maintenanceSystems() {
    return (await ApiResource.Instance.maintenanceSystems).getAssociation()
  }

  get msData() : any[] {
    const systems : any = this.maintenanceSystems
    if (!systems) return []

    return systems.map((s : any) => {
      const data = s.data()
      data.uri = s.resource.uri
      return data
    })
  }

  get systemsTree() {
    this.msData.forEach((f: any)=> {
      let subsystems = this.msData.filter((g:any)=>g.parentSystemId==f.id)
      if (subsystems.length > 0) {
        f.subsystems = subsystems

        f.subsystems.sort((a : any,b : any) => a.name.localeCompare(b.name))
      }
    })

    let tree = this.msData.filter((f:any)=>f.parentSystemId==null)
    // TODO a bit different here... tree[0]
    return tree.length == 0 ? [] : tree[0].subsystems
  }

  onSelect(selection : any[]) {
    this.$emit("input", selection.map((s) => s.uri))
  }
}
