
import {Component, InjectReactive, Mixins} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import MaintenanceItemPackageForm from "@/components/maintenanceSystems/MaintenanceItemPackageForm.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, MaintenanceSystemsResource} from "@/resources"
import { BaseMaintenanceItemPackageComponent } from '../base/BaseMixins'

@Component({components: {BaseResourceFormDialog, MaintenanceItemPackageForm}})
export default class MaintenanceItemPackageFormDialog extends Mixins(ResourceFormDialogMixin, BaseMaintenanceItemPackageComponent) { 

  @InjectReactive() readonly maintenanceSystem!: MaintenanceSystemsResource

  newModelFunction() : any {
    return this.rdata
            ? this.rdata
            : {...SettingsResource.defaultObject("maintenanceItemPackage"), maintenanceSystem: this.maintenanceSystem.resource.uri }
  }

}
