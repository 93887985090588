
import { Component, Vue, Prop } from "vue-property-decorator";
import BaseDialog from "@/components/base/BaseDialog.vue"
@Component({components : {BaseDialog}})
export default class BaseYesNoDialog extends Vue {
    @Prop({ required : true, default: false}) readonly value!: boolean
    @Prop({ default: "Yes"}) readonly buttonTitle!: string
    @Prop({ required : true, default: false}) readonly doing!: boolean
    @Prop({ default: "primary"}) readonly buttonColor!: string
    @Prop({ required: true}) readonly doFunction!: Function
}
