
import { Component, Mixins, Prop } from "vue-property-decorator"
import AsyncComputed from 'vue-async-computed-decorator'
import BaseYesNoDialog from "@/components/base/BaseYesNoDialog.vue"
import BaseOptionsMenu from "@/components/base/BaseOptionsMenu.vue"
import { AbstractMaintenanceSystemList } from "../forms/FormsMixin"
import { EntityResource, MaintenanceItemPrototypeResource } from "@/resources"
import { BaseMaintenanceItemPrototypeComponent } from "../base/BaseMixins"

@Component({components : {BaseOptionsMenu, BaseYesNoDialog}})
export default class MaintenanceItemPackageItemList extends Mixins(AbstractMaintenanceSystemList, BaseMaintenanceItemPrototypeComponent) {

  @Prop({default : false}) readonly showType !: boolean
  seletedItemTypeResourceId : any = null

  // maintenance items are sorted by their model name
  customSortField = (er : EntityResource) => (er as MaintenanceItemPrototypeResource).data().name

  get headers() {
    return this.showType 
    ? [ { text: 'Name', value: 'name' }, { text: 'Item type', value: 'type' }, { text: 'Schema version', value: 'schemaVersion', align: 'center', sortable: false  }, { text: 'Actions', value: 'actions', sortable: false }]
    : [ { text: 'Name', value: 'name' }, { text: 'Actions', value: 'actions', sortable: false }]
  }

  @AsyncComputed()
  async maintenanceItemTypes() {
    return await this.maintenanceSystem.maintenanceItemTypesRolledUp.getAssociation()
  }

  itemPresetName(er : any) {
    return er.getPresetLabel()
  }

  editItem(item : any) {
    this.seletedItemTypeResourceId = item.data().typeId
    this.selectedResource = item
    this.formDialog = true
  }

  showDialog(itemType: any) {
    this.seletedItemTypeResourceId = itemType.data().id
    this.formDialog = true
  }

 }
