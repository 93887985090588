
import { Component, Mixins, ProvideReactive, Watch } from "vue-property-decorator";
import MaintenanceItemPackageFormDialog from "@/components/maintenanceSystems/MaintenanceItemPackageFormDialog.vue"
import MaintenanceItemPackageItemList from "@/components/maintenanceSystems/MaintenanceItemPackageItemList.vue"
import BaseYesNoDialog from "@/components/base/BaseYesNoDialog.vue"
import { AbstractMaintenanceSystemList } from "../forms/FormsMixin";
import { BaseMaintenanceItemPackageComponent } from "../base/BaseMixins";
import { MaintenanceItemPackageResource } from "@/resources";

@Component({components : {MaintenanceItemPackageFormDialog, BaseYesNoDialog, MaintenanceItemPackageItemList}})
export default class MaintenanceItemPackageList extends Mixins(AbstractMaintenanceSystemList, BaseMaintenanceItemPackageComponent) {

  headers: any = [
        { text: 'Name', value: 'name', sortable: true},
        { text: 'Description', value: 'description' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]

  selectedIdx : number | undefined = 0

  @ProvideReactive() maintenanceItemPackageResource : MaintenanceItemPackageResource | null = null

  @Watch("selectedIdx", {immediate: true})
  @Watch("isLoaded", {immediate: true})
  packageSelected() {
    if (this.selectedIdx !== undefined && this.isLoaded) {
      this.maintenanceItemPackageResource = this.resources[this.selectedIdx]
    }
  }

 }
