
import { Component, Vue, Prop, Watch} from "vue-property-decorator"
import { codemirror } from 'vue-codemirror'
import JSON5 from 'json5'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/base16-dark.css'

@Component({components : {codemirror}})
export default class JsonEditor extends Vue {
  @Prop({default: 2}) readonly tabs!:number
  @Prop({default: false}) readonly lineNumbers!:boolean
  @Prop({default: false}) readonly readonly!:boolean
  @Prop({required: true}) readonly value!:Object
  
  parseError : any | null = null
  json : string = JSON5.stringify(this.value ? this.value : {}, null, this.tabs)
  cmOptions : object = {
      tabSize: this.tabs,
      mode: {
        name: 'javascript',
        json: true
      },
      readOnly: this.readonly,
      theme: 'base16-dark',
      lineNumbers: this.lineNumbers,
      line: true,
    }

    refresh(cm : any) {
      // weird bug with initial cursor position/highlighting
      cm.refresh()
    }
   
    @Watch("value")
    valueChanged(val : any) {
        this.json = JSON5.stringify(val ? val : {}, null, this.tabs)
    }

    @Watch("json")
    jsonChanged(val: any) {
      try {
        // eslint-disable-next-line no-unused-vars
        let jsonObject  = val.trim() === "" ? {} : JSON5.parse(val)
        this.parseError = undefined
        if (!this.readonly) this.$emit("input", jsonObject)
      } catch (e : any) {
        this.parseError = e
        this.$emit("error", e.message)
      } 
    }
  }
